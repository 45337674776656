<template>
  <div id="registeredAccount">
    <!-- 头部组件 -->
    <headPageNew />
    <div class="container">
      <div class="loginControlForget">
        <div class="loginTitle">
          用户注册
        </div>
        <div class="loginInput">
          <!-- 注册角色类型id -->
          <el-radio-group v-model="roleKey" class="roleKey" @change="changeRoleKey">
            <el-radio v-for="(item, index) in talentSourceData" :key="index" :label="item.dictCode">{{
            item.dictValue }}</el-radio>
          </el-radio-group>
          <!-- 注册角色-医疗机构负责人 -->
          <el-form ref="ruleForm1" :model="ADMIN_COMPANY_ROLE_Data.ruleForm" :rules="ADMIN_COMPANY_ROLE_Data.rules"
            label-width="" label-position="right" class="elForm" v-if="roleKey == 'ADMIN_COMPANY_ROLE'">
            <el-form-item prop="fullname">
              <el-input type="text" v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.fullname" size="small" clearable
                placeholder="请输入姓名" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="mobile">
              <el-input type="text" v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.mobile" size="small" clearable
                placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item prop="captcha">
              <el-input v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.captcha" type="text" size="small"
                placeholder="请输入验证码">
              </el-input>
              <div class="yzmz"
                :style="ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeDisabled ? 'pointer-events: none;' : ''"
                @click="getCode">
                {{ ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeMsg }}
              </div>
            </el-form-item>
            <el-form-item prop="areaId">
              <el-cascader :options="ADMIN_COMPANY_ROLE_Data.areatreeList" clearable filterable
                :props="ADMIN_COMPANY_ROLE_Data.propsarea" size="small"
                v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.areaId" placeholder="请选择所属区域"></el-cascader>
            </el-form-item>
            <el-form-item prop="compName">
              <el-input type="text" v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.compName" size="small" clearable
                placeholder="请输入机构名称" @blur="nameOfjudgmentOrganization" maxlength="50" show-word-limit />
              <p style="line-height: 20px; font-size: 12px; color: #9e9e9e">
                *请按执业许可证上机构名称填写
              </p>
            </el-form-item>
            <el-form-item prop="adminName">
              <el-input type="text" autocomplete="new-password" v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.adminName"
                clearable size="small" placeholder="请输入登录帐号" maxlength="50" show-word-limit />
              <p style="line-height: 20px; font-size: 12px; color: #9e9e9e">
                *登录账号默认为您的手机号，如有需要请自行更改
              </p>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" autocomplete="new-password" v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.password"
                clearable size="small" placeholder="密码由8-20位且小写字母、大写字母、数字、特殊符号中的三种组合" />
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input type="password" autocomplete="new-password"
                v-model="ADMIN_COMPANY_ROLE_Data.ruleForm.confirmPassword" clearable size="small"
                placeholder="请再次输入密码" />
            </el-form-item>
          </el-form>
          <!-- 注册角色-培训业务负责人 -->
          <el-form ref="ruleForm2" :model="ADMIN_TRAIN_ROLE_Data.ruleForm" :rules="ADMIN_TRAIN_ROLE_Data.rules"
            label-width="" label-position="right" class="elForm" v-if="roleKey == 'ADMIN_TRAIN_ROLE'">
            <div class="ADMIN_TRAIN_ROLE_Data_title">注册信息</div>
            <el-form-item prop="fullname">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.fullname" size="small" clearable
                placeholder="请输入姓名" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="mobile">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.mobile" size="small" clearable
                placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item prop="captcha">
              <el-input v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.captcha" type="text" size="small" placeholder="请输入验证码">
              </el-input>
              <div class="yzmz"
                :style="ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeDisabled ? 'pointer-events: none;' : ''"
                @click="getCode">
                {{ ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeMsg }}
              </div>
            </el-form-item>
            <el-form-item prop="adminName">
              <el-input type="text" autocomplete="new-password" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.adminName"
                clearable size="small" placeholder="请输入登录帐号" maxlength="50" show-word-limit />
              <p style="line-height: 20px; font-size: 12px; color: #9e9e9e">
                *登录账号默认为您的手机号，如有需要请自行更改
              </p>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" autocomplete="new-password" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.password"
                clearable size="small" placeholder="密码由8-20位且小写字母、大写字母、数字、特殊符号中的三种组合" />
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input type="password" autocomplete="new-password"
                v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.confirmPassword" clearable size="small" placeholder="请再次输入密码" />
            </el-form-item>
            <div class="ADMIN_TRAIN_ROLE_Data_title">基本信息</div>
            <el-form-item prop="compName">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compName" size="small" clearable
                placeholder="请输入企业名称" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="compNo">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compNo" size="small" clearable
                placeholder="请输入信用代码" show-word-limit />
            </el-form-item>
            <el-form-item prop="areaId">
              <el-cascader :options="ADMIN_TRAIN_ROLE_Data.areatreeList" clearable filterable
                :props="ADMIN_TRAIN_ROLE_Data.propsarea" size="small" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.areaId"
                placeholder="请选择所属区域"></el-cascader>
            </el-form-item>
            <el-form-item prop="compIndustry">
              <el-select v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compIndustry" clearable filterable
                placeholder="请选择所属行业" size="small">
                <el-option size="small" v-for="item in ADMIN_TRAIN_ROLE_Data.industryList" :key="item.dictCode"
                  :label="item.dictValue" :value="item.dictCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="compLegal">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compLegal" size="small" clearable
                placeholder="请输入法人姓名" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="compIdcard">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compIdcard" size="small" clearable
                placeholder="请输入法人身份证号" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="compUser">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compUser" size="small" clearable
                placeholder="请输入联系人姓名" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item prop="compPhone">
              <el-input type="text" v-model="ADMIN_TRAIN_ROLE_Data.ruleForm.compPhone" size="small" clearable
                placeholder="请输入联系人电话" maxlength="20" show-word-limit />
            </el-form-item>
            <div class="ADMIN_TRAIN_ROLE_Data_title">证照信息<span>请上传企业相关证明材料</span></div>
            <el-form-item prop="upload">
              <el-upload class="" ref="upload" multiple accept="image/png, image/gif, image/jpg, image/jpeg"
                :auto-upload="true" :show-file-list="false" :on-change="handleAvatarSuccess2"
                :http-request="$requestMine" action>
                <el-button size="small" class="bgc-bv"><i class="el-icon-upload el-icon--right"></i>上传文件</el-button>
              </el-upload>
              <p style="color: #f46173;font-size: 12px;">证件材料包括但是不限于营业执照、法人证书和身份证明信息及其他证明</p>
              <div class="flieListDta" v-for="(item, index) in ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys" :key="index">
                <span class="flieListDta_1">{{ item.fileName }}</span>
                <span class="flieListDta_2" @click="previewAndDelete(1, index)">预览</span>
                <span class="flieListDta_3" @click="previewAndDelete(2, index)">删除</span>
              </div>
            </el-form-item>
          </el-form>
          <!-- 注册-操作 -->
          <div class="loginButtonForget">
            <el-button style="background: #f2f5f9" @click="cancelBack">取消</el-button>
            <el-button class="bgc-bv" @click="resetPassword">注册</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 滑动验证 - 弹框 -->
    <el-dialog title="完成验证" :visible.sync="slideVerificationCode" width="440px" :before-close="handleClose"
      v-if="slideVerificationCode">
      <componentsSlideVerify @conSuccess="fonSuccess" />
    </el-dialog>
    <!-- 预览证照 - 弹框 -->
    <el-dialog title="预览证照" :visible.sync="ADMIN_TRAIN_ROLE_Data.previewLoadingData.dialogVisible" width="440px">
      <img :src="ADMIN_TRAIN_ROLE_Data.previewLoadingData.url" alt="" style="width: 100%;">
    </el-dialog>
    <!-- 注册完成的提示 -->
    <div class="registrationCompletedDialog" v-show="registrationSuccessData.registrationCompletedLoading">
      <div class="shell">
        <p class="p1">注册成功</p>
        <p class="p2">恭喜您注册成功，即将跳转到登录页面 ({{ registrationSuccessData.registrationCompletedCountdown }})</p>
        <el-button type="primary" class="bgc-bv" @click="cancelBack">立即登录</el-button>
      </div>
    </div>
    <!-- 底部组件 -->
    <bottomPage />
  </div>
</template>

<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "../wrongAuthenticatePage/bottomPage.vue";
import componentsSlideVerify from "@/components/slideVerify.vue";

export default {
  name: "web_login_registeredAccount",
  components: {
    headPageNew,
    bottomPage,
    componentsSlideVerify,
  },
  data() {
    // 密码校验
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback("请输入新密码");
      } else {
        let result = this.checkPassword(value);
        if (!result.flag) {
          callback(new Error(result.msg));
        } else {
          callback();
        }
      }
    };
    // 两个密码是否相同
    let validateNewPass = (rule, value, callback) => {
      if (this.roleKey == 'ADMIN_COMPANY_ROLE') {
        if (this.ADMIN_COMPANY_ROLE_Data.ruleForm.password && value.trim() != this.ADMIN_COMPANY_ROLE_Data.ruleForm.password.trim()) {
          callback(new Error("请检查两次输入的新密码是否相同"));
        } else {
          callback();
        }
      }
      if (this.roleKey == 'ADMIN_TRAIN_ROLE') {
        if (this.ADMIN_TRAIN_ROLE_Data.ruleForm.password && value.trim() != this.ADMIN_TRAIN_ROLE_Data.ruleForm.password.trim()) {
          callback(new Error("请检查两次输入的新密码是否相同"));
        } else {
          callback();
        }
      }
    };
    // 社会信用代码规则
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    return {
      // 注册角色类型id
      roleKey: "",
      // 角色类型-数据
      talentSourceData: [],
      // 注册角色-医疗机构负责人
      ADMIN_COMPANY_ROLE_Data: {
        // 表单数据
        ruleForm: {
          fullname: "", // 姓名
          mobile: "", // 手机号
          captcha: "", // 验证码
          areaId: "", // 所属区域
          compName: "", // 机构名称
          compId: "", // 机构id
          adminName: "", // 登录帐号
          password: "", // 密码
          confirmPassword: "", // 确认密码
        },
        // 所属区域 - 下拉数据
        areatreeList: [],
        // 所属区域 - 匹配字段
        propsarea: {
          value: "value",
          label: "label",
          emitPath: false,
          checkStrictly: true,
        },
        // 数据校验
        rules: {
          fullname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
          mobile: [
            { required: true, validator: this.$validatePhone, trigger: "blur" },
          ],
          captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
          areaId: [
            { required: true, message: "请选择所属区域", trigger: "change" },
          ],
          compName: [
            { required: true, message: "请输入机构名称", trigger: "blur" },
          ],
          adminName: [
            { required: true, message: "请输入登录帐号", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            { validator: validatePass, trigger: "blur" },
          ],
          confirmPassword: [
            { required: true, message: "请再次输入密码", trigger: "blur" },
            { validator: validateNewPass, trigger: "blur" },
          ],
        },
        // 验证码-相关数据
        slideVerifyData: {
          codeMsg: "获取验证码", // 手机验证码 - 文字
          authTimeTimer: null, // 定时器
          auth_time: "180", // 手机验证码 - 倒计时
          codeDisabled: false, // 手机验证码 - 发送验证码后禁止点击
        }
      },
      // 注册角色-培训业务负责人
      ADMIN_TRAIN_ROLE_Data: {
        // 表单数据
        ruleForm: {
          fullname: "", // 姓名
          mobile: "", // 手机号
          captcha: "", // 验证码
          adminName: "", // 登录帐号
          password: "", // 密码
          confirmPassword: "", // 确认密码
          compName: "", // 企业名称
          compNo: "", // 信用代码
          areaId: "", // 所属区域
          compIndustry: "", // 所属行业
          compLegal: "", // 法人姓名
          compIdcard: "", // 法人身份证号
          compUser: "", // 联系人
          compPhone: "", // 联系人电话
          fileKeys: [], // 证件信息
        },
        // 所属区域 - 下拉数据
        areatreeList: [],
        // 所属区域 - 匹配字段
        propsarea: {
          value: "value",
          label: "label",
          emitPath: false,
          checkStrictly: true,
        },
        // 所属行业 - 下拉数据
        industryList: [],
        // 数据校验
        rules: {
          fullname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
          mobile: [
            { required: true, validator: this.$validatePhone, trigger: "blur" },
          ],
          captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
          adminName: [
            { required: true, message: "请输入登录帐号", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            { validator: validatePass, trigger: "blur" },
          ],
          confirmPassword: [
            { required: true, message: "请再次输入密码", trigger: "blur" },
            { validator: validateNewPass, trigger: "blur" },
          ],
          compName: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
          compNo: [
            { required: true, trigger: "blur", validator: creditCode },
          ],
          areaId: [
            { required: true, message: "请选择所属区域", trigger: "change" },
          ],
          compIndustry: [
            { required: true, message: "请选择所行业", trigger: "change" },
          ],
          compLegal: [{ required: true, message: "请输入法人姓名", trigger: "blur" }],
          compIdcard: [{ required: true, validator: this.$validateIdCard, trigger: "blur" }],
          compUser: [{ required: true, message: "请输入联系人", trigger: "blur" }],
          compPhone: [{ required: true, validator: this.$validatePhone, trigger: "blur" }],
        },
        // 验证码-相关数据
        slideVerifyData: {
          codeMsg: "获取验证码", // 手机验证码 - 文字
          authTimeTimer: null, // 定时器
          auth_time: "180", // 手机验证码 - 倒计时
          codeDisabled: false, // 手机验证码 - 发送验证码后禁止点击
        },
        // 预览证照弹框数据
        previewLoadingData: {
          dialogVisible: false, // 弹框状态
          url: "", // 预览证照的url
        }
      },
      // 滑动验证组件 - 弹出
      slideVerificationCode: false,
      // 注册成功弹框数据
      registrationSuccessData: {
        registrationCompletedLoading: false, // 弹框
        registrationCompletedTimer: null, // 定时器
        registrationCompletedCountdown: 4, // 3秒自动返回的时间
      }
    };
  },
  created() {
    this.getInvoice_type();
    this.getareatree();
  },
  methods: {
    // 校验密码规则
    checkPassword(str) {
      //校验密码，密码长度8-20，必须包含大写字母、小写字母、数字、特殊符号中的三种
      let rC = {
        lW: "[a-z]",
        uW: "[A-Z]",
        nW: "[0-9]",
        sW: "[\\u0020-\\u002F\\u003A-\\u0040\\u005B-\\u0060\\u007B-\\u007E]",
      };
      function Reg(str, rStr) {
        let reg = new RegExp(rStr);
        if (reg.test(str)) return true;
        else return false;
      }
      if (str.length < 8) {
        return { flag: false, msg: "密码长度不得少于8位" };
      } else if (str.length > 20) {
        return { flag: false, msg: "密码长度不得超过20位" };
      } else {
        let tR = {
          l: Reg(str, rC.lW),
          u: Reg(str, rC.uW),
          n: Reg(str, rC.nW),
          s: Reg(str, rC.sW),
        };
        if (
          (tR.l && tR.u && tR.n) ||
          (tR.l && tR.u && tR.s) ||
          (tR.s && tR.u && tR.n) ||
          (tR.s && tR.l && tR.n)
        ) {
          return { flag: true, msg: "" };
        } else {
          return {
            flag: false,
            msg: "密码需由  小写字母、大写字母、数字、特殊符号  中的任意三种组成",
          };
        }
      }
    },
    // change注册角色类型id的时候清空校验
    changeRoleKey(val) {
      if (val == 'ADMIN_COMPANY_ROLE') {
        this.$refs.ruleForm1.resetFields();
      }
      if (val == 'ADMIN_TRAIN_ROLE') {
        this.$refs.ruleForm2.resetFields();
      }
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 角色
      this.$post("/sys/dictionary/key", {
        dictKey: "ADMIN_REGISTRY_ROLE_KEY",
      })
        .then((res) => {
          if (res.status == "0") {
            this.talentSourceData = res.data || []
            this.roleKey = res.data[0].dictCode || "";
          }
        })
        .catch(() => {
          return;
        });
      // 行业
      this.$post("/sys/dictionary/key", {
        dictKey: "INDUSTRY",
      })
        .then((res) => {
          this.ADMIN_TRAIN_ROLE_Data.industryList = res.data || [];
        })
        .catch(() => {

          return;
        });
    },
    // 获取所属区域
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.ADMIN_COMPANY_ROLE_Data.areatreeList = res.data || [];
          this.ADMIN_TRAIN_ROLE_Data.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 判断机构名称
    nameOfjudgmentOrganization() {
      if (this.ADMIN_COMPANY_ROLE_Data.ruleForm.compName) {
        this.$post("/sys/company/isExist", {
          compName: this.ADMIN_COMPANY_ROLE_Data.ruleForm.compName,
          roleKey: this.roleKey,
        })
          .then((res) => {
            if (res.data.compId) {
              this.ADMIN_COMPANY_ROLE_Data.ruleForm.compId = res.data.compId;
            }
          })
          .catch((err) => {
            if (err?.data?.status == -1) {
              this.ADMIN_COMPANY_ROLE_Data.ruleForm.compName = "";
              this.ADMIN_COMPANY_ROLE_Data.ruleForm.compId = "";
            }
          });
      }
    },
    // 获取验证码 - 弹出滑动验证组件
    getCode() {
      if (this.roleKey == 'ADMIN_COMPANY_ROLE') {
        this.$refs.ruleForm1.validateField("mobile", (phoneError) => {
          if (!phoneError) {
            this.slideVerificationCode = true;
          }
        });
      }
      if (this.roleKey == 'ADMIN_TRAIN_ROLE') {
        this.$refs.ruleForm2.validateField("mobile", (phoneError) => {
          if (!phoneError) {
            this.slideVerificationCode = true;
          }
        });
      }
    },
    // 滑动验证 - 成功
    fonSuccess(ztype) {
      if (ztype == "ok") {
        if (this.roleKey == 'ADMIN_COMPANY_ROLE') {
          this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.auth_time = 180;
          this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeDisabled = false;
          this.$post("/sys/sms/register/captcha", {
            mobile: this.ADMIN_COMPANY_ROLE_Data.ruleForm.mobile,
          })
            .then((res) => {
              this.$message({
                type: "success",
                message: "验证成功",
              });
              this.ADMIN_COMPANY_ROLE_Data.ruleForm.adminName = this.ADMIN_COMPANY_ROLE_Data.ruleForm.mobile;
            })
            .catch((err) => {
              return;
            });
        }
        if (this.roleKey == 'ADMIN_TRAIN_ROLE') {
          this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.auth_time = 180;
          this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeDisabled = false;
          this.$post("/sys/sms/register/captcha", {
            mobile: this.ADMIN_TRAIN_ROLE_Data.ruleForm.mobile,
          })
            .then((res) => {
              this.$message({
                type: "success",
                message: "验证成功",
              });
              this.ADMIN_TRAIN_ROLE_Data.ruleForm.adminName = this.ADMIN_TRAIN_ROLE_Data.ruleForm.mobile;
            })
            .catch((err) => {
              return;
            });
        }
      }
      this.getAuthCode();
      this.slideVerificationCode = false;
    },
    // 验证码倒计时
    getAuthCode() {
      if (this.roleKey == 'ADMIN_COMPANY_ROLE') {
        if (this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.authTimeTimer) {
          clearTimeout(this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.authTimeTimer);
        }
        this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.authTimeTimer = setTimeout(() => {
          this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.auth_time -= 1;
          this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeMsg = this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.auth_time + "s";
          this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeDisabled = true;
          if (this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.auth_time < 0) {
            this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeDisabled = false;
            this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.codeMsg = "获取验证码";
            clearTimeout(this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.authTimeTimer);
          } else {
            this.getAuthCode();
          }
        }, 1000);
      }
      if (this.roleKey == 'ADMIN_TRAIN_ROLE') {
        if (this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.authTimeTimer) {
          clearTimeout(this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.authTimeTimer);
        }
        this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.authTimeTimer = setTimeout(() => {
          this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.auth_time -= 1;
          this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeMsg = this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.auth_time + "s";
          this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeDisabled = true;
          if (this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.auth_time < 0) {
            this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeDisabled = false;
            this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.codeMsg = "获取验证码";
            clearTimeout(this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.authTimeTimer);
          } else {
            this.getAuthCode();
          }
        }, 1000);
      }
    },
    // 图片上传
    handleAvatarSuccess2(res) {
      if (res.size / 1024 / 1024 > 2) {
        this.$message.error("文件大小不能超过2MB");
        return;
      }
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file", res.raw);
      formData.append("fileType", extension);
      formData.append("folder", "LECTURER");
      formData.append("isPublic", true);
      const _this = this;
      _this.$Postformat("/sys/upload/cooperateApply", formData)
        .then((res1) => {
          this.ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys.push({
            fileName: res.name,
            ...res1.data
          })
        })
        .catch(() => {
          setTimeout((err) => {
            _this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 预览&删除
    previewAndDelete(type, index) {
      // 预览
      if (type == 1) {
        console.log(this.ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys[index].fileURL)
        this.ADMIN_TRAIN_ROLE_Data.previewLoadingData.url = this.ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys[index].fileURL;
        this.ADMIN_TRAIN_ROLE_Data.previewLoadingData.dialogVisible = true;
      }
      // 删除
      if (type == 2) {
        this.ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys.splice(index, 1)
      }
    },
    // 注册
    resetPassword() {
      if (this.roleKey == 'ADMIN_COMPANY_ROLE') {
        this.$refs.ruleForm1.validate((valid) => {
          if (valid) {
            this.ADMIN_COMPANY_ROLE_Data.ruleForm.fullname = this.ADMIN_COMPANY_ROLE_Data.ruleForm.fullname.replace(/^\s+|\s+$/g, ""); // 去两边空格
            this.ADMIN_COMPANY_ROLE_Data.ruleForm.adminName = this.ADMIN_COMPANY_ROLE_Data.ruleForm.adminName.replace(/^\s+|\s+$/g, ""); // 去两边空格
            if (!this.ADMIN_COMPANY_ROLE_Data.ruleForm.fullname) {
              this.$message({
                type: "warning",
                message: "请输入正确的姓名",
              });
              return
            }
            if (!this.ADMIN_COMPANY_ROLE_Data.ruleForm.adminName) {
              this.$message({
                type: "warning",
                message: "请输入正确的账号",
              });
              return
            }
            this.$post("/sys/admin/comp/register", {
                roleKey: this.roleKey,
                ...this.ADMIN_COMPANY_ROLE_Data.ruleForm,
              }).then((res) => {
                if (res.status == "0") {
                  this.$message({
                    type: "success",
                    message: "注册成功",
                  });
                  this.$refs.ruleForm1.resetFields();
                  this.registrationSuccessData.registrationCompletedLoading = true;
                  this.automaticReturn();
                }
              });
          }
        });
      }
      if (this.roleKey == 'ADMIN_TRAIN_ROLE') {
        this.$refs.ruleForm2.validate((valid) => {
          if (valid) {
            this.ADMIN_TRAIN_ROLE_Data.ruleForm.fullname = this.ADMIN_TRAIN_ROLE_Data.ruleForm.fullname.replace(/^\s+|\s+$/g, ""); // 去两边空格
            this.ADMIN_TRAIN_ROLE_Data.ruleForm.adminName = this.ADMIN_TRAIN_ROLE_Data.ruleForm.adminName.replace(/^\s+|\s+$/g, ""); // 去两边空格
            if(!this.ADMIN_TRAIN_ROLE_Data.ruleForm.fullname){
              this.$message({
                type: "warning",
                message: "请输入正确的姓名",
              });
              return
            }
            if(!this.ADMIN_TRAIN_ROLE_Data.ruleForm.adminName){
              this.$message({
                type: "warning",
                message: "请输入正确的账号",
              });
              return
            }
            let companyRegistryInfoDTO = {
              compName: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compName,
              compNo: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compNo,
              areaId: this.ADMIN_TRAIN_ROLE_Data.ruleForm.areaId,
              compIndustry: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compIndustry,
              compLegal: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compLegal,
              compIdcard: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compIdcard,
              compUser: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compUser,
              compPhone: this.ADMIN_TRAIN_ROLE_Data.ruleForm.compPhone,
              fileKeys: [],
            }
            for (let i = 0; i < this.ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys.length; i++) {
              companyRegistryInfoDTO.fileKeys.push(this.ADMIN_TRAIN_ROLE_Data.ruleForm.fileKeys[i].fileKey)
            }
            this.ADMIN_TRAIN_ROLE_Data.ruleForm.companyRegistryInfoDTO = companyRegistryInfoDTO;
            let cData = {
              roleKey: this.roleKey,
              ...this.ADMIN_TRAIN_ROLE_Data.ruleForm
            }
            this.$post("/sys/admin/comp/register", cData).then((res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "注册成功",
                });
                this.$refs.ruleForm2.resetFields();
                this.registrationSuccessData.registrationCompletedLoading = true;
                this.automaticReturn();
              }
            });
          }
        });
      }
    },
    // 取消&返回
    cancelBack() {
      this.$router.push({
        path: "/web/login",
        query: {
          loginShowFlag: 'true',
          loginShowStu: 'false'
        }
      });
    },
    // 注册成功 - 3秒自动返回
    automaticReturn() {
      this.registrationSuccessData.registrationCompletedTimer = setInterval(() => {
        --this.registrationSuccessData.registrationCompletedCountdown;
        if (this.registrationSuccessData.registrationCompletedCountdown < 1) {
          clearInterval(this.registrationSuccessData.registrationCompletedTimer);
          this.cancelBack();
        }
      }, 1000);
    },
  },
  // 销毁页面时出发
  beforeDestroy() {
    if (this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.authTimeTimer) {
      clearInterval(this.ADMIN_COMPANY_ROLE_Data.slideVerifyData.authTimeTimer);
    }
    if (this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.authTimeTimer) {
      clearInterval(this.ADMIN_TRAIN_ROLE_Data.slideVerifyData.authTimeTimer);
    }
    if (this.registrationSuccessData.registrationCompletedTimer) {
      clearInterval(this.registrationSuccessData.registrationCompletedTimer);
    }
  },
};
</script>

<style lang="less" scoped>
#registeredAccount {
  height: 100%;
  overflow-y: auto;
  background: #f8fafd;

  .container {
    flex: 1;
    width: 100%;
    padding: 60px 0 90px;
    background: #F9FAFC;

    .loginControlForget {
      width: 500px;
      margin: 0 auto;

      .loginTitle {
        text-align: center;
        padding: 30px 0 20px;
        font-size: 26px;
      }

      .loginInput {
        .roleKey {
          display: block;
          text-align: center;
          margin-bottom: 20px;
        }

        .ADMIN_TRAIN_ROLE_Data_title {
          font-size: 18px;

          span {
            font-size: 14px;
            margin-left: 20px;
          }
        }

        /deep/ .el-upload {
          margin-top: 20px;
          width: 5rem;
          height: 28px !important;
          border: none !important;

          .el-button {
            padding: 0 14px !important;
          }
        }

        .flieListDta {
          line-height: 24px;
          font-size: 12px;

          .flieListDta_1 {
            width: 340px;
          }

          .flieListDta_2 {
            width: 30px;
            text-align: right;
            margin-left: 10px;
            color: #2878FF;
            cursor: pointer;
          }

          .flieListDta_3 {
            width: 30px;
            text-align: right;
            margin-left: 10px;
            color: #f46173;
            cursor: pointer;
          }
        }
      }

      .loginButtonForget {
        padding: 1.25rem 0 0;
        display: flex;
        text-decoration: none;

        >.el-button {
          width: 100%;
          height: 2.5rem;
        }

        .bgc-bv {
          background: #2878FF !important;
          border-color: #2878FF !important;

          &:hover {
            background: #2878FF !important;
            border-color: #2878FF !important;
          }
        }
      }
    }

    .elForm {
      box-shadow: 0px 6px 50px 1px #eeeeee;
      padding: 40px 40px 10px 40px;
      border-radius: 6px;

      .yzmz {
        position: absolute;
        top: 0;
        right: 20px;
        color: #2878ff;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}

/deep/ .slide-verify-slider-mask-item {
  width: 38px !important;
  height: 38px !important;
}

/deep/ .el-dialog__header {
  padding: 5px 0;
}

// 注册完成的提示
.registrationCompletedDialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;

  .shell {
    width: 500px;
    height: 250px;
    background: #fff;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -250px;
    margin-top: -150px;

    .p1 {
      font-size: 26px;
      text-align: center;
      margin-top: 50px;
    }

    .p2 {
      text-align: center;
      margin-top: 40px;
    }

    button {
      display: block;
      margin: auto;
      width: 60%;
      margin-top: 20px;
    }
  }
}

/deep/.el-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #2878ff !important;
  background-color: #2878ff !important;
}
</style>