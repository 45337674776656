<template>
  <!-- 滑动验证组件 -->
  <div>
    <slide-verify :l="42" :r="10" :w="400" :h="155" @success="onSuccess" @fail="onFail" @refresh="onRefresh"
      :imgs="slideImgs" :slider-text="'向右滑→'"></slide-verify>
  </div>

</template>

<script>
export default {
  name: "zslideVerify",
  components: {

  },
  data() {
    return {
      slideImgs: [
        require("../assets/slideVerify/1.png"),
        require("../assets/slideVerify/2.png"),
        require("../assets/slideVerify/3.png"),
        require("../assets/slideVerify/4.png"),
        require("../assets/slideVerify/5.png"),
        require("../assets/slideVerify/6.png"),
        require("../assets/slideVerify/7.png"),
        require("../assets/slideVerify/8.png"),
      ],
    };
  },
  created() { },
  methods: {
    // 滑动验证 - 成功
    onSuccess() {
      this.$emit("conSuccess", "ok");
    },
    // 滑动验证 - 失败
    onFail() { },
    // 滑动验证 - 刷新
    onRefresh() { },
  },
};
</script>

<style lang="less" scoped>
/deep/ .slide-verify-slider-mask-item {
  width: 38px !important;
  height: 38px !important;
}
</style>